
















































import { Component, Vue, Emit, Watch } from 'vue-property-decorator';
import FormTemplate from '@/components/swine/layout/FormTemplate.vue';
import { FormStyling } from '@/models/swine/form/form.model';
import { RequestCodeStores } from '@/store/swine/request-code/index';
import { SendRequestCodeEmailParams } from '@/models/swine/request-code.model';
@Component({
  components: {
    FormTemplate,
  },
})
export default class RequestCodeModal extends Vue {
  formTitle = 'Request Code Form';
  formStyling: FormStyling = {
    height: '600px',
    borderRadius: '20px',
    width: '900px',
  };
  store = RequestCodeStores.detail;
  regionalEmailList: string[] = [];
  adminEmailList: string[] = [];
  emailBody = '';
  isSendButtonDisabled = true;

  mounted() {
    this.store.getEmailList();
  }

  @Watch('store.emailListResponse')
  updateEmailDetails() {
    if (this.store.emailListResponse) {
      this.regionalEmailList = this.store.emailListResponse.regionals;
      this.adminEmailList = this.store.emailListResponse.admins;
    }
  }

  @Emit('close')
  closeForm(value: boolean) {
    return value;
  }

  updateEmailBody(payload: string) {
    this.emailBody = payload;
    this.isSendButtonDisabled = this.emailBody.length > 0 ? false : true;
  }

  sendRequestCodeEmail() {
    const params: SendRequestCodeEmailParams = {
      body: this.emailBody,
    };
    this.store.sendRequestEmail(params);
  }

  @Watch('store.requestCodeEmailResponse')
  checkCurrentStatus() {
    if (
      this.store.requestCodeEmailResponse &&
      this.store.requestCodeEmailResponse === 202
    ) {
      this.closeForm(true);
    }
  }
}
