
























import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { FormStyling } from '@/models/swine/form/form.model';

@Component({})
export default class FormTemplate extends Vue {
  @Prop({
    type: String,
    default: 'Base Title',
  })
  formTitle!: string;

  @Prop({
    type: Object,
  })
  formStyle!: FormStyling;

  @Prop({
    type: Boolean,
    default: false,
  })
  isTitleCenter!: boolean;

  @Emit('closeForm')
  closeForm() {
    return false;
  }
}
