import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from 'vuex-module-decorators';
import store from '@/store/swine';
import {
  EmailListResponse,
  SendRequestCodeEmailParams,
} from '@/models/swine/request-code.model';
import { axios } from '@/utils/axios';
import { Endpoints } from '@/utils/endpoints';
import { ToastProgrammatic } from 'buefy';
@Module({
  namespaced: true,
  dynamic: true,
  name: 'request-code.detail',
  store,
})
class RequestCodeDetail extends VuexModule {
  isLoading = false;
  error: any | null = null;
  emailListResponse: EmailListResponse | null = null;
  requestCodeEmailResponse: number | null = null;

  @Mutation
  updateLoadingStatus(payload: boolean) {
    this.isLoading = payload;
  }

  @Mutation
  setError(payload: any) {
    this.error = payload;
  }

  @Mutation
  setEmailListResponse(payload: EmailListResponse | null) {
    this.emailListResponse = payload;
  }

  @Mutation
  setRequestCodeEmailResponse(payload: number | null) {
    this.requestCodeEmailResponse = payload;
  }

  @Action
  getEmailList() {
    this.context.commit('updateLoadingStatus', true);
    this.context.commit('setError', null);
    this.context.commit('setEmailListResponse', null);
    try {
      axios
        .get<EmailListResponse>(`${Endpoints.RequestCode}`)
        .then((response) =>
          this.context.commit('setEmailListResponse', response.data),
        );
    } catch (error) {
      this.context.commit('setError', error);
    }
    this.context.commit('updateLoadingStatus', false);
  }

  @Action
  sendRequestEmail(params: SendRequestCodeEmailParams) {
    this.context.commit('updateLoadingStatus', true);
    this.context.commit('setError', null);
    this.context.commit('setRequestCodeEmailResponse', null);
    try {
      axios.post(`${Endpoints.RequestCode}`, params).then((response) => {
        this.context.commit('setRequestCodeEmailResponse', response.data);
        if (response.data === 202) {
          ToastProgrammatic.open({
            type: 'is-dark',
            duration: 3000,
            message: `Request Code email has been sent successfully`,
          });
        } else {
          ToastProgrammatic.open({
            type: 'is-danger',
            duration: 3000,
            message: `Request Code email was not sent successfully. Please try again`,
          });
        }
      });
    } catch (error) {
      this.context.commit('setError', error);
    }
    this.context.commit('updateLoadingStatus', false);
  }
}

export default getModule(RequestCodeDetail);
